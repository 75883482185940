import React, { useState, useEffect }  from "react"
import withUserData from "../withUserData"
import { api_base_url, browserstring, getCurrentUser, handleLoginAdmin } from "../../utils/auth"
import * as styles from "../Donation/main.module.css"
import styled from "styled-components"
import { Link } from "gatsby"
import InfoBlock from "../info-block"
import DonationForm from "../DonationForm"
import DonatorBalance from "../DonatorBalance"
import SubscriptionForm from "../SubscriptionForm"
import { useNavigationDispatch } from "../navigation-context"
import withLocation from "../withLocation"

const axios = require('axios').default;


const TextContainer = styled.div`
  margin: 0;
  padding: 0.25rem;
`

const CenteredContainer = styled.div`
  text-align: center;
`

const RecipientsLink = styled.p`
  font-size: 0.7em;
`

const Subscription = ({ search, location, userData }) => {

  const { r, s, st, sub, pt } = search
  const { username, cookie } = getCurrentUser()
  const [isFinished, setIsFinished] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [infoMessage, setInfoMessage] = useState(""); // eslint-disable-line
  const [additionalMessage, setAdditionalMessage] = useState(""); // eslint-disable-line
  const [errorMessage, setErrorMessage] = useState("");
  const [balance, setBalance] = useState(-1);
  const [donationSource, setDonationSource] = useState("");
  const [displayReturnLink, setDisplayReturnLink] = useState(false);

  const dispatch = useNavigationDispatch();

  useEffect(() => {

    if (s === "referrer") {
      if (!document.referrer || document.referrer === "") {

        setDonationSource("Not provided");
        setDisplayReturnLink(false);
      } else {
        setDonationSource(document.referrer);
        setDisplayReturnLink(true);
      }
    } else {
      setDonationSource(s);
    }

    dispatch({type: 'donationpage', target: `${location.pathname}${location.search}`})

    if (userData && userData.Account_A) {
      setBalance(userData.Account_A.Balance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const subscriptionSubmitted = (source, error, subscription_status="new") => {
    if (error) {
      setErrorMessage(error)
    } else {
      setErrorMessage(undefined)
      setIsFinished(true);
      setSubscriptionStatus(subscription_status);

      if (source !== "Not provided") {
        if (displayReturnLink) {
          setTimeout(function() {
            window.location.replace(source);
          }, 3000);
        } else {
          window.parent.postMessage("DonationCompleted", source);
          window.postMessage("DonationCompleted", source);
        }
      }
    }
  }

  const containers = (content) => {
    return (
      <div className={styles.outercontainer}>
        <div className={styles.container}>
          {content}
        </div>
      </div>
    )
  }


  if (!username) {
    return (
      containers(<p>Okänd användare</p>)
    )
  }

  const handleChangeBalance = val => {

    setBalance(val);
  }

  const donationpage_path = location.pathname+location.search;

  const confirmation_text = () => {
    if (subscriptionStatus === "active") {
      return "Du har en aktiv prenumeration. Tack för ditt stöd!"
    } else {
      return "Tack för prenumerationen!"
    }
  }


  if (!userData || !userData.Account_A) {
    return (
      containers(
        <div>
          <div className={"p-3"}>
            <div style={{ display: "block" }} className={styles.errormessage}>
              Du är inloggad som mottagare och det är därför inte möjligt för dig
              att köpa en prenumeration. För att kunna köpa en prenumeration måste du logga
              in som en vanlig användare.
            </div>
            <div className={"mt-3"}>
              <Link to="/app/profile/">Klicka här</Link> för att komma till din profilsida.
            </div>

          </div>
        </div>
      )
    )
  } else {
    return (
      <CenteredContainer>
        <div style={{ display: isFinished ? "none" : "block" }}>
          {/*<DonatorHeader donator_name={username} />*/}
          <TextContainer>
            <InfoBlock infotext={infoMessage}/>
          </TextContainer>
          <div
            style={{ display: errorMessage ? "block" : "none" }}
            className={styles.errormessage}
            dangerouslySetInnerHTML={{__html: errorMessage}}/>

          <div style={{ display: (balance < 0) ? "none" : "block" }}>
            <SubscriptionForm postAction={subscriptionSubmitted} sub={sub} source={s} recipient={r} sender={username}
                          site={st} pageAccessTypes={pt} cookie={cookie}/>
          </div>
          <InfoBlock infotext={additionalMessage}/>

          <DonatorBalance donationpage={donationpage_path} account_data={userData.Account_A} donator_name={username}
                          cookie={cookie} onChangeValue={handleChangeBalance}/>
        </div>
        <div style={{ display: isFinished ? "block" : "none" }}>
          <h4>
            {confirmation_text()}
          </h4>
          <p style={{ display: displayReturnLink && donationSource!=="Not provided" ? "block" : "none" }} className={["smaller"].join(" ")}>
            Du skickas strax tillbaka. Eller <a href={donationSource}>klicka här</a> om du inte vill vänta.
          </p>
        </div>
      </CenteredContainer>
    )
  }
}
export default withUserData(Subscription)
