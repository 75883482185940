// extracted by mini-css-extract-plugin
export var b_r_high = "main-module--b_r_high--wn6tS";
export var b_r_low = "main-module--b_r_low--HE4Xn";
export var b_r_mid = "main-module--b_r_mid--1+mSh";
export var b_r_reset = "main-module--b_r_reset--kH1Pu";
export var b_r_subscribe = "main-module--b_r_subscribe--Yh+iv";
export var b_r_subscribe_wider = "main-module--b_r_subscribe_wider--+gdSM";
export var boldinfo = "main-module--boldinfo--Az4Uk";
export var buttonContainer = "main-module--buttonContainer--UccZ9";
export var buttonconfirmtext = "main-module--buttonconfirmtext--1gKOc";
export var controlbutton = "main-module--controlbutton--OYXO9";
export var donationCard = "main-module--donationCard--CnuR1";
export var recurringText = "main-module--recurringText--RyH+H";
export var resetButtonContainer = "main-module--resetButtonContainer--D7Sdf";
export var submitButtonContainer = "main-module--submitButtonContainer--WGM1P";
export var subscriptionButtonWrapper = "main-module--subscriptionButtonWrapper--2FdmN";
export var sumContainer = "main-module--sumContainer--yFsEV";
export var textCenter = "main-module--text-center--0oFvt";
export var textLeft = "main-module--text-left--ASH9K";