import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import * as styles from "./main.module.css"
import { api_base_url, browserstring } from "../../utils/auth"
import tippy from "tippy.js"
import "tippy.js/themes/light-border.css"
import "./tippy.css"
import { CreditCard, Repeat } from "react-feather"
import ShareEmail from "../share-email"
const axios = require('axios').default;

const SubscriptionForm = ({ sub, recipient, source, site, pageAccessTypes, sender, cookie, postAction }) => {
  const [a, setAmount] = useState(0);
  const [st, setSite] = useState("");
  const [shareEmail, setShareEmail] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [subscriptionIsRecurring, setSubscriptionIsRecurring] = useState(true);
  const [tippyInstances, setTippyInstances] = useState(null);

  const handleSubscriptionUpdate = (data) => {

    for (let pageAccessType of pageAccessTypes.split(",")) {
      for (let subscription of data.ActiveSubscriptions) {
        if (subscription.subscriptionType === pageAccessType) {
          postAction(source, "", "active");
          return
        }
      }
    }

    setActiveSubscriptions(data.ActiveSubscriptions)
    setSiteName(data.siteName)
    setSiteUrl(data.url)
    setSubscriptionTypes(data.subscriptionTypes)
  }

  const updateAmount = (sub) => {
    if (sub && sub.length > 0) {
      for (const subscriptionType of subscriptionTypes) {
        for (const subscriptionPackage of subscriptionType.packages) {
          if (sub === subscriptionType.typeName + "~~~" + subscriptionPackage.packageName) {
            setAmount(subscriptionPackage.price)
          }
        }
      }
    }
  }

  useEffect(() => {
    setSelectedSubscription(sub)
  }, [sub])

  useEffect(() => {
    updateAmount(selectedSubscription);
    updateRecurringButtonsTooltips(subscriptionIsRecurring);
  }, [subscriptionTypes])


  const updateRecurringButtonsTooltips = (isRecurring) => {
    const textContent = isRecurring ?
      "Klicka här för att ändra till engångsprenumeration":
      "Klicka här för att göra prenumerationen återkommande";

    let allInstances = tippyInstances;
    if (allInstances === null ||allInstances.length === 0) {

      allInstances = tippy(document.getElementsByName('Repeat'));
    }

    for (const instance of allInstances) {

      instance.setContent(textContent);
    }

    setTippyInstances(allInstances);
  }


  useEffect(() => {
    setSite(site);

    const fetchSubscriptionTypes = (dataCallback) => {

      const options = {
        method: "post",
        url: `${api_base_url()}/site/${site}/products`,
        data: {
          BrowserData: browserstring(),
          PageUrl: source,
          CookieID: cookie,

        },
      }
      axios(options)
        .then(response => {
          if (response.status === 204) {
            dataCallback([])
          } else {
            dataCallback(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    fetchSubscriptionTypes(handleSubscriptionUpdate)

  }, [site])


  const submitSubscription = (e) => {
    setIsSubmitting(true)
    setTimeout(() => {
      axios({
        method: "post",
        url: `${api_base_url()}/donation`,
        data: {
          CookieID: cookie,
          BrowserData: browserstring(),
          PageUrl: source,
          NewPackage: selectedSubscription,
          SubscriptionRecurring: subscriptionIsRecurring,
          SiteID: st,
          ShareEmail: shareEmail,
        },
      }).then(function(response) {
        if (response.status === 200) {
          setIsSubmitting(false)
          postAction(source)
        } else {
          setIsSubmitting(false)
          postAction(source, "Donationen misslyckades. Inga pengar har dragits från ditt konto.")
        }

      })
        .catch(function(error) {
          postAction(source, "Donationen misslyckades. Inga pengar har dragits från ditt konto.")
          setIsSubmitting(false)
        })
    }, 400)
  }

  if (document.getElementById("donatebtn")) {
    tippy(document.getElementById("donatebtn"))
  }
  if (document.getElementById("submitbtn")) {
    tippy(document.getElementById("submitbtn"))
  }

  const packageSelectionItems = []
  let originalPackageSelectionItem = null

  const handleSubscriptionSelection = (event) => {
    const target = event.target
    const value = target.value
    setSelectedSubscription(value)
    setAmount(target.dataset.amount)
  }

  const toggleRecurringState = (event) => {

    updateRecurringButtonsTooltips(!subscriptionIsRecurring);
    setSubscriptionIsRecurring(!subscriptionIsRecurring);
  }

  const isButtonDisabled = (value, isSubmitting) => {
    return (isSubmitting || value !== selectedSubscription)
  }

  const getPackageSelectionItemSource = (subscriptionType, subscriptionPackage) => {
    const isSelected = selectedSubscription === subscriptionType.typeName + "~~~" + subscriptionPackage.packageName;
    const recurringButtonColor = subscriptionIsRecurring ? 'green': 'red';
    const recurringText = subscriptionIsRecurring ? "(Förnyas automatiskt)": "(Förnyas inte automatiskt)";
    return (
      <div key={subscriptionType.typeName + " - " + subscriptionPackage.packageName}
           className={styles.subscriptionButtonWrapper}>
        <div><input
          name={subscriptionType.typeName + " - " + subscriptionPackage.packageName} type="checkbox"
          value={subscriptionType.typeName + "~~~" + subscriptionPackage.packageName}
          checked={selectedSubscription === subscriptionType.typeName + "~~~" + subscriptionPackage.packageName}
          data-amount={subscriptionPackage.price}
          onChange={handleSubscriptionSelection}/></div>
        <div
          className={isSelected ? styles.boldinfo : ""}>
          {subscriptionType.typeName + " - " + subscriptionPackage.packageName}
          {subscriptionPackage.recurringOption ? (
            <span className={styles.recurringText}>
            <Repeat
              name={'Repeat'}
              data-tippy-theme="light-border"
              className={['ml-2', 'mr-2'].join(" ")}
              strokeWidth={3}
              color={isSelected ? recurringButtonColor : "grey"}
              size={16}
              onClick={toggleRecurringState}/>
              {isSelected ? (recurringText) : null}
              </span>
          ):null}
        </div>
        <div>
          <button className={styles.b_r_subscribe}
                  disabled={isSubmitting || isButtonDisabled(subscriptionType.typeName + "~~~" + subscriptionPackage.packageName)}
                  value={selectedSubscription} onClick={submitSubscription}>
            <div className="text-center">
              <div>{subscriptionPackage.price} kr</div>
            </div>
          </button>
        </div>
      </div>
    )
  }

  if (subscriptionTypes) {
    for (const subscriptionType of subscriptionTypes) {
      for (const subscriptionPackage of subscriptionType.packages) {
        if (subscriptionType.typeName + "~~~" + subscriptionPackage.packageName === sub) {
          originalPackageSelectionItem =
            getPackageSelectionItemSource(subscriptionType, subscriptionPackage)
        } else {
          packageSelectionItems.push(
            getPackageSelectionItemSource(subscriptionType, subscriptionPackage),
          )
        }
      }
    }
  }

  const buttonConfirmText = () => {
    if (selectedSubscription) {
      return "Bekräfta köp av " + selectedSubscription.replace("~~~", ", ")
    } else {
      return "Ingen prenumeration vald"
    }
  }

  return (

    <div>
      <p className={["px-5", styles.textCenter].join(" ")}>
        Bekräfta och beställ din prenumeration av {siteName}.
      </p>

      {originalPackageSelectionItem &&
      <div>
        <p className="px-5">

        </p>
        <div className="m-0 mb-1">
          {originalPackageSelectionItem}
        </div>

        {packageSelectionItems.length > 0 &&

          <p className={["px-5", styles.textCenter].join(" ")}>
            Klicka på den gröna knappen för att bekräfta och beställa din prenumeration
            eller välj en annan prenumerationstyp nedan.
          </p>
        }
      </div>
      }

      <div className="m-0 mb-3">
        {packageSelectionItems}
      </div>

      <div className="m-0 mb-3">
        <ShareEmail checked={shareEmail} setChecked={setShareEmail} recipient_name={siteName} />
      </div>

      <div className="m-0 mb-3">
        <div className={styles.buttonContainer}>

          <div className={[styles.sumContainer, styles.controlbutton].join(" ")}>
            <button data-tippy-theme="light-border" data-tippy-content="Klicka här för att köpa vald prenumeration"
                    id="submitbtn"
                    className={styles.b_r_subscribe_wider} disabled={isSubmitting || a < 1} value={selectedSubscription}
                    onClick={submitSubscription}>
              {isSubmitting ? (
                  <div className="spinner-border" role="status">
                  </div>) :
                (
                  <div className={"text-left"}>
                    <div className={styles.buttonconfirmtext}>{buttonConfirmText()}</div>
                    <div>Belopp: {a} kr</div>
                  </div>
                )}

            </button>
          </div>

        </div>
      </div>

    </div>
  )
}

SubscriptionForm.propTypes = {
  sub: PropTypes.string,
  site: PropTypes.string,
  recipient: PropTypes.string,
  sender: PropTypes.string,
  cookie: PropTypes.string,
  postAction: PropTypes.func,
}

SubscriptionForm.defaultProps = {
  sub: ``,
  recipient: ``,
  sender: ``,
  cookie: ``,
  site: ``,
}

export default SubscriptionForm