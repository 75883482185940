import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"


const ShareEmailCheckboxContainer = styled.div`
  font-size: 0.7em;
`

const ShareEmailLabel= styled.label`
  word-wrap:break-word;
`

const ShareEmailCheckbox= styled.input`
  vertical-align:middle;
`

const ShareEmail = ({ recipient_name, checked, setChecked }) => {

  return (

    <ShareEmailCheckboxContainer>
      <ShareEmailLabel htmlFor="checkid">
        <ShareEmailCheckbox  defaultChecked={checked}
                             onChange={() => setChecked(!checked)} id="checkid" type="checkbox" value="test" className={"mr-2"}/>Jag godkänner att min epostadress delas med {recipient_name}
      </ShareEmailLabel>
    </ShareEmailCheckboxContainer>
  )
}

ShareEmail.propTypes = {
  recipient_name: PropTypes.string,
}

ShareEmail.defaultProps = {
  recipient_name: ``,
}

export default ShareEmail